<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-right: 10px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>

      <el-select v-model="state" placeholder="车主查询状态">
        <el-option label="全部" value="0"></el-option>
        <el-option label="未通过" value="3"></el-option>
        <el-option label="已通过" value="2"></el-option>
      </el-select>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="resetSearch">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="150">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="真实姓名" width="150">
        </el-table-column>
        <el-table-column prop="checkDate" label="审核时间" width="200">
        </el-table-column>
        <el-table-column
          prop="licenseExpireDate"
          label="驾照失效时间"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="drivingRegDate"
          label="行驶证注册时间"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="travelNum" label="出行次数"> </el-table-column>

        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      valueArea: "",
      options: [],
      phone: "", // 查询手机号
      state: "", // 查询车主状态
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "车主审核",
        },
        {
          name: "审核列表查询",
        },
      ], // 面包屑数据

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getDriverList,
      method: "POST",
      params: JSON.stringify({
        nonce: "",
        pageNum: 1,
        pageSize: 20,
        sign: "",
        status: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.phone || this.state || this.valueArea) {
        showLoading();
        const opt = {
          url: reqUrl.getDriverList,
          method: "POST",
          params: JSON.stringify({
            nonce: "",
            pageNum: 1,
            pageSize: 0,
            sign: "",
            status: this.state,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 分页
    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getDriverList,
        method: "POST",
        params: JSON.stringify({
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          sign: "",
          status: this.state,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row) {
      localStorage.setItem("ownerListID", row.examineId);
      this.$router.push({
        // 跳转到详情页
        path: "/AuditListDetails",
        params: {
          ...row,
        },
      });
    },

    // 重置
    resetSearch() {
      if (this.phone || this.state || this.valueArea) {
        this.phone = "";
        this.state = "";
        this.valueArea = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  .el-input {
    width: 300px;
    margin-right: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}
</style>
